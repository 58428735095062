import React, { useRef } from "react";

import { ArrowDown } from "../../helpers/Icons";

import "../../styles/components/elements/scroll-to-target.scss";

const ScrollToTarget = ({ className, target }) => {
  const targetRef = useRef(null);

  const handleScroll = () => {
    const ref = target || targetRef;
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <button
        type="button"
        className={`scroll-to-target ${className || ""}`}
        aria-label="Next section"
        onClick={handleScroll}
        onKeyPress={(e) => {
          if (e.key === "enter") handleScroll();
        }}
      >
        <ArrowDown />
      </button>
      {!target && <div ref={targetRef} aria-hidden="true" />}
    </>
  );
};

export default ScrollToTarget;
