import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import { Col, Container, Row } from "react-bootstrap";

import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import HeaderBackground from "../components/blocks/HeaderBackground";
import Anchor from "../components/elements/Anchor";
import Pagination from "../components/common/Pagination";
import { constructActiveDoc } from "../helpers";

import "../styles/pages/news.scss";

const News = ({ data, pageContext }) => {
  if (!data) return null;

  const { settings, page, posts } = data;
  const { title, description, image, image_mobile: imageMobile } = page.data;

  const postList = posts.edges.map((post) => {
    const { url, first_publication_date: firstPublicationDate } = post.node;
    const {
      title: postTitle,
      image: postImage,
      publication_date: publicationDate,
    } = post.node.data;

    return (
      <Row
        as={Anchor}
        href={url}
        title={postTitle}
        className="news__post mb-5 mx-1 py-4 link-unstyled"
        key={url}
      >
        <Col sm={4}>
          <GatsbyImage
            image={postImage.gatsbyImageData}
            alt={postImage.alt || postTitle || ""}
            className="news__post-image mb-3 mb-sm-0"
          />
        </Col>
        <Col sm={8}>
          <p className="text-primary">
            {publicationDate || firstPublicationDate}
          </p>
          <h2 className="h3 text-decoration-underline">{postTitle}</h2>
        </Col>
      </Row>
    );
  });

  return (
    <>
      <Seo page={page} settings={settings} itemsList={posts} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
        <article className="news-page">
          <HeaderBackground
            title={title}
            description={description}
            image={image}
            imageMobile={imageMobile}
            headerSize="2"
          />
          <Container className="mt-6" fluid>
            <Row>
              <Col
                xl={{ span: 10, offset: 1 }}
                className="col-xxxl-6 offset-xxxl-3"
              >
                {postList}
              </Col>
            </Row>
            <Pagination pageInfo={posts.pageInfo} base={pageContext.base} />
          </Container>
        </article>
      </Layout>
    </>
  );
};

export const query = graphql`
  query newsQuery($lang: String, $limit: Int!, $skip: Int!) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicNews(lang: { eq: $lang }) {
      ...prismicNewsFragment
    }
    posts: allPrismicPost(
      sort: { fields: data___publication_date, order: DESC }
      limit: $limit
      skip: $skip
      filter: { lang: { eq: $lang } }
    ) {
      edges {
        node {
          _previewable
          data {
            title
            publication_date(formatString: "D/M/YYYY")
            image {
              alt
              dimensions {
                height
                width
              }
              gatsbyImageData(placeholder: BLURRED)
              url
            }
          }
          url
          first_publication_date(formatString: "D/M/YYYY")
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
    }
  }
`;

export default withPrismicPreview(News);
